<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Minimal Stock Item</h2>
            </CCardHeader>
            <CCardBody>
                <CSelect id="StorageId" class="font-weight-bold" :options="StorageData" :value.sync="StorageId" @change="onChange()"/>
            
                <minimal-stock-item-grid :key="gridReload" v-bind:storage-id="this.StorageId" :editClick="editClick"/>
                <minimal-stock-item-form ref="MinimalStockItemForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import minimalStockItemServices from './Script/MinimalStockItemServices';
import minimalStockItemGrid from './Grid/MinimalStockItemGrid.vue';
import MinimalStockItemForm from './Component/MinimalStockItemForm.vue';

export default {
    name: 'MinimalStockItem',
    components: {
        'minimal-stock-item-grid' : minimalStockItemGrid,
        'minimal-stock-item-form' : MinimalStockItemForm,
    },
    async mounted () {
        this.StorageData = await minimalStockItemServices.getStorageId();
        this.StorageId = this.StorageData[0].value;
    },
    data(){
        return{
            StorageId: '',
            StorageData : [],
            gridReload: 0,
        }
    },
    methods: {
        onChange(){
            this.reload();
        },
        editClick(data, view){
            this.$refs.MinimalStockItemForm.editClick(data, view);
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>
